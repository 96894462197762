// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------



$lighGray: #999;
$branding : #b8d734;

// WHITE
$white: #fff;
$seashell: #f1f1f1;
$concrete: #f2f2f2;
$wild-sand: #f4f4f4;
$wild-sand-lighter: lighten($wild-sand, .5%);
$wild-sand-lighter2: lighten($wild-sand, .75%);
$alabaster: #f7f7f7;
$alabaster-lighter: lighten($alabaster, .5%);
$athens-gray: #fcfcfd;
$black: #000;
$mine-shaft: #393939;
$dove-gray: #6a6a6a;
$tuna: #323232;
$silver: #ccc;
$gray: #7E7E7E;
$mercury: #E9E9E9;
$nomad: #B2B0A3;
$storm-gray: #6f717c;
$dawn: #a7a598;


//RED
$guardsman-red: #CC0000;
$bright-red: #a60000;
$red-berry: #990000;

//BLUE
$curious-blue: #2a9eda;
$cerulean: #1e623b;

// TEXT COLORS
$text-color-primary: $white;
$text-color-secondary: $black;
$text-color-secondary-shade1: $mine-shaft;
$text-color-secondary-shade2: $silver;
$text-color-secondary-shade4: $gray;

// $text-color-secondary-shade2: ;
$text-color-tertiary: $curious-blue;
$text-color-brand: $cerulean;
$text-color-brand-shade1: $bright-red;
$text-warning: #d9534f;


// $text-color-brand-shade2: ;
$text-color-danger: $guardsman-red;

// BG COLORS
$bg-brand: $cerulean;
// $bg-brand-shade1: ;
$bg-brand-shade2: $red-berry;
$bg-primary: $white;
$bg-primary-shade1: $athens-gray;
$bg-primary-shade2: $wild-sand-lighter;
$bg-primary-shade3: $wild-sand-lighter2;
$bg-primary-shade4: $alabaster;
$bg-primary-shade5: $alabaster-lighter;
$bg-primary-shade6: $seashell;
$bg-primary-shade7: $concrete;
$bg-secondary: $black;
$bg-secondary-shade1: $mine-shaft;
$bg-secondary-shade2: $dove-gray;
$bg-secondary-shade3: $tuna;
$bg-secondary-shade4: $gray;
$bg-secondary-shade5: $mercury;
$bg-secondary-shade6: $storm-gray;
$bg-secondary-shade7: $dawn;

$bg-tertiary: $cerulean;
$bg-forth: $nomad;

// // BORDER COLOR
// $border-color-brand: ;
// $border-color-brand-shade1: ;
// $border-color-brand-shade2: ;
// $border-color-primary: ;

$borderColor : #d2d0d1;

$primaryFont:'Source Sans Pro', sans-serif;
// $primaryFontBold: 'ralewaybold';
// $primaryFontMedium: 'ralewaysemibold';
$secondaryFont: 'nevis', sans-serif;


/// Copy text color
/// @type Color
$text-color: rgb(0, 0, 0) !default;

/// Main brand color
/// @type Color
$brand-color: rgb(229, 0, 80) !default;

/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: #333 !default;





/// Container's maximum width
/// @type Length
$max-width: 1610px !default;



/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;

$step: 1; // step of % for created classes
$loops: 100;
$increment: (360 / $loops);
$half: round($loops / 2);
@for $i from 0 through $loops {
  .progress-#{$i*$step} {
    @if $i < 50 {
		$nextdeg: 90deg + ( $increment * $i );
		background-image: linear-gradient(90deg, #dbdbdb 50%, transparent 50%, transparent), linear-gradient($nextdeg, #4fbfe1 50%, #dbdbdb 50%, #dbdbdb);
    }
    @else {
		$nextdeg: -90deg + ( $increment * ( $i - $half ) );
		background-image: linear-gradient($nextdeg, #4fbfe1 50%, transparent 50%, transparent), linear-gradient(270deg, #4fbfe1 50%, #dbdbdb 50%, #dbdbdb);
    }
  }
}

@for $i from 0 through $loops {
  .progress-cancel-#{$i*$step} {
    @if $i < 50 {
		$nextdeg: 90deg + ( $increment * $i );
		background-image: linear-gradient(90deg, #dbdbdb 50%, transparent 50%, transparent), linear-gradient($nextdeg, $text-color-danger 50%, #dbdbdb 50%, #dbdbdb);
    }
    @else {
		$nextdeg: -90deg + ( $increment * ( $i - $half ) );
		background-image: linear-gradient($nextdeg, $text-color-danger 50%, transparent 50%, transparent), linear-gradient(270deg, $text-color-danger 50%, #dbdbdb 50%, #dbdbdb);
    }
  }
}



