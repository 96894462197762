// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table;
}

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
.container {
  max-width: $max-width; /* 1 */
  margin-left: auto; /* 2 */
  margin-right: auto; /* 2 */
  padding-left: 15px; /* 3 */
  padding-right: 15px; /* 3 */
  width: 100%; /* 1 */
 }

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden { 
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
@media (min-width: 1200px) and (max-width: 1600px){
    .container {
      max-width: 1170px;
    }
}

.text-size{
  opacity: .6;
}

.flexbox{
    @include flexbox();
    &.flex--space-around{
      @include justify-content(space-around);
    }
    &.flex--space-between{
      @include justify-content(space-between);
    }
    &.flex--align_center{
      @include align-items(center);
    }
    &.flex--align_top{
      @include align-items(flex-start);
    }
}

.flexbox-lg{
    @include breakpoint("min-width: 1200px"){
        @include flexbox();
    }
}

.nopadding{
  padding: 0 !important;
}
.noptop{
  padding-top: 0;
}
.noborder{
  border: 0;
}
// backgrounds
.bg-primary-shade3{
  background: $bg-primary-shade3;
}

.pagination.pagination--second{
  li{
    &.active{
      a{
        background: $bg-primary;
        color: $text-color-secondary;
      }
    }
    a{
      color: #fff;
      background-color: transparent;
      border: 0;
    }
  }
}

/* **************** General Spacing ***************** */
/*Padding (top): by 5px */
.ptop1 { padding-top: 5px;}
.ptop2 { padding-top: 10px;}
.ptop3 { padding-top: 15px;}
.ptop4 { padding-top: 20px;}
.ptop5 { padding-top: 25px;}
.ptop6 { padding-top: 30px;}
.ptop7 { padding-top: 35px;}
.ptop8 { padding-top: 40px;}
.ptop9 { padding-top: 45px;}
.ptop10 { padding-top: 50px;}
.ptop11 { padding-top: 55px;}
.ptop12 { padding-top: 60px;}
.ptop13 { padding-top: 65px;}
.ptop14 { padding-top: 70px;}
.ptop15 { padding-top: 75px;}
.ptop16 { padding-top: 80px;}
.ptop17 { padding-top: 85px;}
.ptop18 { padding-top: 90px;}
.ptop19 { padding-top: 95px;}
.ptop20 { padding-top: 100px;}
.ptop21 { padding-top: 105px;}
.ptop22 { padding-top: 110px;}
.ptop23 { padding-top: 115px;}
.ptop24 { padding-top: 120px;}
.ptop25 { padding-top: 125px;}
.ptop26 { padding-top: 130px;}
.ptop27 { padding-top: 135px;}
.ptop28 { padding-top: 140px;}
.ptop29 { padding-top: 145px;}
.ptop30 { padding-top: 150px;}

@include breakpoint("phone-large"){
  .ptop11 { padding-top: 5px;}
  .ptop12 { padding-top: 10px;}
  .ptop13 { padding-top: 15px;}
  .ptop14 { padding-top: 20px;}
  .ptop15 { padding-top: 25px;}
  .ptop16 { padding-top: 30px;}
  .ptop17 { padding-top: 35px;}
  .ptop18 { padding-top: 40px;}
  .ptop19 { padding-top: 45px;}
  .ptop20 { padding-top: 50px;}
  .ptop21 { padding-top: 55px;}
  .ptop22 { padding-top: 60px;}
  .ptop23 { padding-top: 65px;}
  .ptop24 { padding-top: 70px;}
  .ptop25 { padding-top: 75px;}
  .ptop26 { padding-top: 80px;}
  .ptop27 { padding-top: 85px;}
  .ptop28 { padding-top: 90px;}
  .ptop29 { padding-top: 95px;}
  .ptop30 { padding-top: 100px;} 
}

/*Padding (right): by 5px */
.pright1 { padding-right: 5px;}
.pright2 { padding-right: 10px;}
.pright3 { padding-right: 15px;}
.pright4 { padding-right: 20px;}
.pright5 { padding-right: 25px;}
.pright6 { padding-right: 30px;}
.pright7 { padding-right: 35px;}
.pright8 { padding-right: 40px;}
.pright9 { padding-right: 45px;}
.pright10 { padding-right: 50px;}
.pright11 { padding-right: 55px;}
.pright12 { padding-right: 60px;}

/*Padding (bottom): by 5px */
.pbottom1 { padding-bottom: 5px;}
.pbottom2 { padding-bottom: 10px;}
.pbottom3 { padding-bottom: 15px;}
.pbottom4 { padding-bottom: 20px;}
.pbottom5 { padding-bottom: 25px;}
.pbottom6 { padding-bottom: 30px;}
.pbottom7 { padding-bottom: 35px;}
.pbottom8 { padding-bottom: 40px;}
.pbottom9 { padding-bottom: 45px;}
.pbottom10 { padding-bottom: 50px;}
.pbottom11 { padding-bottom: 55px;}
.pbottom12 { padding-bottom: 60px;}
.pbottom13 { padding-bottom: 65px;}
.pbottom14 { padding-bottom: 70px;}
.pbottom15 { padding-bottom: 75px;}
.pbottom16 { padding-bottom: 80px;}
.pbottom17 { padding-bottom: 85px;}
.pbottom18 { padding-bottom: 90px;}
.pbottom19 { padding-bottom: 95px;}
.pbottom20 { padding-bottom: 100px;}
.pbottom21 { padding-bottom: 105px;}
.pbottom22 { padding-bottom: 110px;}
.pbottom23 { padding-bottom: 115px;}
.pbottom24 { padding-bottom: 120px;}
.pbottom25 { padding-bottom: 125px;}
.pbottom26 { padding-bottom: 130px;}
.pbottom27 { padding-bottom: 135px;}
.pbottom28 { padding-bottom: 140px;}
.pbottom29 { padding-bottom: 145px;}
.pbottom30 { padding-bottom: 150px;}

@include breakpoint("phone-large"){
  .pbottom11 { padding-bottom: 5px;}
  .pbottom12 { padding-bottom: 10px;}
  .pbottom13 { padding-bottom: 15px;}
  .pbottom14 { padding-bottom: 20px;}
  .pbottom15 { padding-bottom: 25px;}
  .pbottom16 { padding-bottom: 30px;}
  .pbottom17 { padding-bottom: 35px;}
  .pbottom18 { padding-bottom: 40px;}
  .pbottom19 { padding-bottom: 45px;}
  .pbottom20 { padding-bottom: 50px;}
  .pbottom21 { padding-bottom: 55px;}
  .pbottom22 { padding-bottom: 60px;}
  .pbottom23 { padding-bottom: 65px;}
  .pbottom24 { padding-bottom: 70px;}
  .pbottom25 { padding-bottom: 75px;}
  .pbottom26 { padding-bottom: 80px;}
  .pbottom27 { padding-bottom: 85px;}
  .pbottom28 { padding-bottom: 90px;}
  .pbottom29 { padding-bottom: 95px;}
  .pbottom30 { padding-bottom: 100px;} 
}

@include breakpoint("phone"){
  .pbottom11 { padding-bottom: 5px;}
  .pbottom12 { padding-bottom: 10px;}
  .pbottom16 { padding-bottom: 10px;}
  .pbottom16 { padding-bottom: 10px;}
  .pbottom16 { padding-bottom: 10px;}
  .pbottom16 { padding-bottom: 20px;}
  .pbottom17 { padding-bottom: 20px;}
  .pbottom18 { padding-bottom: 20px;}
  .pbottom19 { padding-bottom: 25px;}
  .pbottom20 { padding-bottom: 30px;}
  .pbottom21 { padding-bottom: 35px;}
  .pbottom22 { padding-bottom: 40px;}
  .pbottom23 { padding-bottom: 45px;}
  .pbottom24 { padding-bottom: 50px;}
  .pbottom25 { padding-bottom: 55px;}
  .pbottom26 { padding-bottom: 60px;}
  .pbottom27 { padding-bottom: 65px;}
  .pbottom28 { padding-bottom: 70px;}
  .pbottom29 { padding-bottom: 75px;}
  .pbottom30 { padding-bottom: 80px;} 
}

/*Padding (left): by 5px */
.pleft1 { padding-left: 5px;}
.pleft2 { padding-left: 10px;}
.pleft3 { padding-left: 15px;}
.pleft4 { padding-left: 20px;}
.pleft5 { padding-left: 25px;}
.pleft6 { padding-left: 30px;}
.pleft7 { padding-left: 35px;}
.pleft8 { padding-left: 40px;}
.pleft9 { padding-left: 45px;}
.pleft10 { padding-left: 50px;}


/*Margin (top): by 5px */
.mtop1 { margin-top: 5px;}
.mtop2 { margin-top: 10px;}
.mtop3 { margin-top: 15px;}
.mtop4 { margin-top: 20px;}
.mtop5 { margin-top: 25px;}
.mtop6 { margin-top: 30px;}
.mtop7 { margin-top: 35px;}
.mtop8 { margin-top: 40px;}
.mtop9 { margin-top: 45px;}
.mtop10 { margin-top: 50px;}

  .pullmtop1 { margin-top: -5px;}
  .pullmtop2 { margin-top: -10px;}
  .pullmtop3 { margin-top: -15px;}
  .pullmtop4 { margin-top: -20px;}
  .pullmtop5 { margin-top: -25px;}
  .pullmtop6 { margin-top: -30px;}
  .pullmtop7 { margin-top: -35px;}
  .pullmtop8 { margin-top: -40px;}
  .pullmtop9 { margin-top: -45px;}
  .pullmtop10 { margin-top: -50px;}

/*margin (right): by 5px */
.mright1 { margin-right: 5px;}
.mright2 { margin-right: 10px;}
.mright3 { margin-right: 15px;}
.mright4 { margin-right: 20px;}
.mright5 { margin-right: 25px;}
.mright6 { margin-right: 30px;}
.mright7 { margin-right: 35px;}
.mright8 { margin-right: 40px;}
.mright9 { margin-right: 45px;}
.mright10 { margin-right: 50px;}
  
  .pullmright1 { margin-right: -5px;}
  .pullmright2 { margin-right: -10px;}
  .pullmright3 { margin-right: -15px;}
  .pullmright4 { margin-right: -20px;}
  .pullmright5 { margin-right: -25px;}
  .pullmright6 { margin-right: -30px;}
  .pullmright7 { margin-right: -35px;}
  .pullmright8 { margin-right: -40px;}
  .pullmright9 { margin-right: -45px;}
  .pullmright10 { margin-right: -50px;}

/*margin (bottom): by 5px */
.mbottom1 { margin-bottom: 5px;}
.mbottom2 { margin-bottom: 10px;}
.mbottom3 { margin-bottom: 15px;}
.mbottom4 { margin-bottom: 20px;}
.mbottom5 { margin-bottom: 25px;}
.mbottom6 { margin-bottom: 30px;}
.mbottom7 { margin-bottom: 35px;}
.mbottom8 { margin-bottom: 40px;}
.mbottom9 { margin-bottom: 45px;}
.mbottom10 { margin-bottom: 50px;}
.mbottom11 { margin-bottom: 55px;}
.mbottom12 { margin-bottom: 60px;}
.mbottom13 { margin-bottom: 65px;}
.mbottom14 { margin-bottom: 70px;}
.mbottom15 { margin-bottom: 75px;}
.mbottom16 { margin-bottom: 80px;}
.mbottom17 { margin-bottom: 85px;}

  .pullmbottom1 { margin-bottom: -5px;}
  .pullmbottom2 { margin-bottom: -10px;}
  .pullmbottom3 { margin-bottom: -15px;}
  .pullmbottom4 { margin-bottom: -20px;}
  .pullmbottom5 { margin-bottom: -25px;}
  .pullmbottom6 { margin-bottom: -30px;}
  .pullmbottom7 { margin-bottom: -35px;}
  .pullmbottom8 { margin-bottom: -40px;}
  .pullmbottom9 { margin-bottom: -45px;}
  .pullmbottom10 { margin-bottom: -50px;}

/*margin (left): by 5px */
.mleft1 { margin-left: 5px;}
.mleft2 { margin-left: 10px;}
.mleft3 { margin-left: 15px;}
.mleft4 { margin-left: 20px;}
.mleft5 { margin-left: 25px;}
.mleft6 { margin-left: 30px;}
.mleft7 { margin-left: 35px;}
.mleft8 { margin-left: 40px;}
.mleft9 { margin-left: 45px;}
.mleft10 { margin-left: 50px;}

  .pullmleft1 { margin-left: -5px;}
  .pullmleft2 { margin-left: -10px;}
  .pullmleft3 { margin-left: -15px;}
  .pullmleft4 { margin-left: -20px;}
  .pullmleft5 { margin-left: -25px;}
  .pullmleft6 { margin-left: -30px;}
  .pullmleft7 { margin-left: -35px;}
  .pullmleft8 { margin-left: -40px;}
  .pullmleft9 { margin-left: -45px;}
  .pullmleft10 { margin-left: -50px;}


/*No Margin*/
.nomargin { margin: 0 !important;}

.preloader {
    background-color: rgba(0,0,0,.9);
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100000000;
    text-align: center;
    display: flex; 
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.preloader > p {
    color: #fff;
    font-family: "Raleway",sans-serif;
    font-size: 1.6vw;
    font-weight: bold;
    letter-spacing: 4px;
    text-align: center;
    text-transform: uppercase;
}

.load{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);
  /*change these sizes to fit into your project*/
  width:100px;
  height:100px;
}
.load hr{border:0;margin:0;width:40%;height:40%;position:absolute;border-radius:50%;animation:spin 2s ease infinite}

.load :first-child{background:#19A68C;animation-delay:-1.5s}
.load :nth-child(2){background:#F63D3A;animation-delay:-1s}
.load :nth-child(3){background:#FDA543;animation-delay:-0.5s}
.load :last-child{background:#193B48}

@keyframes spin{
  0%,100%{transform:translate(0)}
  25%{transform:translate(160%)}
  50%{transform:translate(160%, 160%)}
  75%{transform:translate(0, 160%)}
}