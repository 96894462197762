// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
@import url(../fonts/font-awesome/css/font-awesome.min.css);
.wrapper{
    position: relative;
    top: 0;
    height: 100%;
    .sidemenu{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 260px;
        height: 100%;
        max-height: 100%;
        height: 100%;
        overflow-y: auto;
        background-color: $bg-secondary-shade3;
        @include breakpoint("desktop-medium"){
            width: 240px;
        }
        .sidemenu_welcome{
            color: $text-color-primary;
            min-height: 90px;
            max-height: 90px;
            padding: 25px 20px;
            border-bottom: 1px solid rgba(128, 204, 244, .3);
            margin-bottom: 5px;
            @include breakpoint("desktop-medium"){
                margin-bottom: 10px;
            }
            p{
                margin: 0;
                @include font-size(14px);
                font-weight: 300;
            }
            a{
                display: block;
                color: #168471;
                @include font-size(16px);
                i{
                    color: $text-color-primary;
                }
            }
            .dropdown-menu{
                a{
                    color:#333333;
                    transition: background-color .3s ease;
                }
            }
        }
        .sidemenu__logo{
            img{
                max-height: 80px;
                margin: 0 auto;
                display: block;
                width: 100%;
            }
        }
    }
    .main-content{
        width: calc(100% - 260px);
        float: right;
        z-index: 2;
        background-color: $bg-primary-shade2;
        height: 100%;
        @include breakpoint("desktop-medium"){
            width: calc(100% - 240px);
        }
        .header-main{
            background-color: #323232;
            @include flexbox();
            @include justify-content(space-between);
            @include align-items(center);
            color: $text-color-primary;
            min-height: 75px;
            .col-xs-12{
                @include flexbox();
                @include align-items(center);
            }
            .header-main_date{
                font-weight: 600;
            }
            .header-main_cart-notif{
                a{
                    margin-left: 10px;
                    color: #e9e9e9;
                    @include font-size(35px);

                    &.btn{
                        font-weight: 600;
                        @include font-size(16px);
                    }
                    span.badge{
                        padding: 5px;
                        position: relative;
                        top: -8px;
                        &.badge--cart{
                            left: -5px;
                            background-color: #00a2d3;
                        }
                        &.badge--notif{
                            left: -17px;
                            background-color: #ee8277;
                        }
                    }
                }
            }
        }
        .content{
            padding: 30px 0;
            @include breakpoint("desktop-medium"){
                padding: 20px 0;
            }
            .content__title{
                @include font-size(24px);
                margin: 15px 0 30px;
                font-weight: 400;
            }
            .panel-heading__title{
                @include font-size(18px);
                color: $text-color-brand;
                font-weight: 600;
                @include breakpoint("desktop"){
                    @include font-size(16px);
                }
            }
        }
    }

}

.panel-heading--flex{
    @include flexbox();
    @include align-items(center);
}

.menu {
    margin:0;
    padding:0;
    a {
        display: block;
        color: $text-color-secondary-shade2;
        @include font-size(14px);
        text-transform: uppercase;
        font-weight: 600;
        padding: 12px 20px 12px;
        position:relative;
        @include prefix((
            transition: background-color 0.5s ease-in-out
        ), webkit moz);


        @include on-event{
            background-color: #202020;
        }

        img{
            position: relative;
            top: -1px;
            margin-right: 15px;
        }
        i {
            margin-right: 15px;
            width: 25px;
            @include font-size(17px);

            &.arrow {
                position: absolute;
                right: 10px;
                width: auto;
                margin: 0;
            }
        }
    }
}