// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.head-info{
    @include flexbox();
    .col-md-9{
        padding-top: 2%;
    }
    .sales-today{
        padding-top: 35px;
        margin-right: 6%;
        padding-right: 0;
        @include breakpoint("desktop-medium"){
            margin-right: 4%;
        }
        img{
            margin-right: 10px;
        }
        h1{
            @include font-size(48px);
            color: #33cc66;
            font-weight: 600;
            @include breakpoint("desktop"){
                @include font-size(40px);
            }
            @include breakpoint("desktop-medium"){
                @include font-size(32px);
            }
        }
        p{
            @include font-size(24px);
            @include breakpoint("desktop"){
                @include font-size(20px);
            }
            @include breakpoint("desktop-medium"){
                @include font-size(16px);
            }
        }
        small{
            @include font-size(18px);
            @include breakpoint("desktop-medium"){
                @include font-size(16px);
            } 
        }
    }

    .visitor-signup{
        padding: 0;
        h1{
            @include font-size(48px);
            @include breakpoint("desktop"){
                margin: 15px 0;
                @include font-size(40px);
            }
            @include breakpoint("desktop"){
                margin: 15px 0;
                @include font-size(32px);
            }
            &.visitor--count{
                color: #e38b1c;
            }
            &.signup--count{
                color: #40bbd3;
            }
        }
        .col-xs-6{
            border-left: 1px solid #e5e5e5;
            padding: 30px 0;
            p{
                margin: 0;
            }
        }
    }
}
.home{
    .panel{
        width: 100%;
    }
    .table{
        .label{
            @include font-size(16px);
            font-weight: 600;
        }
        @include breakpoint("desktop-medium"){
            @include font-size(12px);
            tr{
                th, td{
                    padding: 8px;
                }
            }
        }
        tr{
            th,td{
                @include breakpoint("hd"){
                    &:first-child{
                        padding-left: 25px;
                    }
                    &:last-child{
                        padding-right: 25px;
                    }
                }
            }
        }
    }
    .heading--large{
        padding: 30px 25px 10px !important;
        .dropdown-menu{
            left: initial;
            right: 0;
        }
        button{
            border: 0;
            background: transparent;
        }
    }
    .web-conversion{
        p{
            margin: 0;
        }
        .web-conversion__percentage{
            margin-bottom: 40px;
            @include breakpoint("desktop-medium"){
                margin-bottom: 20px;
            }
            .item-percentage{
                margin-bottom: 50px;
                @include breakpoint("desktop-medium"){
                    margin-bottom: 30px;
                }
                &:last-child{
                    margin-bottom: 0;
                }
                p{
                    @include font-size(14px);
                }
                h1{
                    @include font-size(30px);
                    color: #e38b1c;
                    margin: 0;
                    @include breakpoint("desktop-medium"){
                        @include font-size(24px);
                    }
                    span{
                        @include font-size(18px);
                    }
                }
            }
            .flexbox{
                @include justify-content(flex-end);
                @include align-items(center);
                @include flex-direction(column);
                overflow: hidden;
                .line-dash{
                    border-right: 3px dashed #cccccc;
                    height: 93%;
                    width: 2px;
                    position: absolute;
                    z-index: 1;
                    top: -1px;
                }
            }
            .circle-icon{
                @include flexbox();
                @include justify-content(center);
                @include align-items(center);
                background: #00a2d3;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                color: #fff;
                border: 3px solid #cccccc;
                margin-bottom: 50px;
                position: relative;
                z-index: 2;
                @include breakpoint("desktop-medium"){
                    width: 40px;
                    height: 40px;
                    i{
                        @include font-size(14px);
                    }
                }
                &:last-child{
                    margin-bottom: 0;
                }
            }

        }
        .web-conversion__count{
            border-top: 1px solid #ccc;
            padding: 15px 20px;   
            @include flexbox();
            @include justify-content(space-between);
            @include align-items(center);
            p{
                margin-left: 15px;
                small{
                    display: block;
                }
            }
        }
    }
}