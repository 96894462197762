// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn{
	transition: all .3s ease;
}
.btn2{
	outline: 0;
	border: 1px solid transparent;
	transition: .5s ease;
	cursor: pointer;
	padding: 12px 20px;
	border-radius: 5px;
	color:white;
	line-height: 1.3;
	@include font-size(18px);
	@include breakpoint("desktop"){
		padding: 10px 15px;
		@include font-size(18px);
	}
	@include breakpoint("desktop-medium"){
		@include font-size(16px);
	}
}
.btn3, .small-btn{
	outline: 0;
	border: 1px solid transparent;
	transition: .5s ease;
	cursor: pointer;
	padding: 9px 15px;
	border-radius: 5px;
	color: $text-color-primary;
	display: inline-block;
	font-family: $primaryFont;
	vertical-align: top;
	line-height:1;
	@include breakpoint("desktop-medium"){
		padding: 15px 20px;
		@include font-size(16px);
	}
	&.btn-save-dropdown{
		margin-left: -3px;
	}
}
.small-btn {
	padding:8px 10px;
	@include font-size(14px);
}
.text--white{
	color: $text-color-primary !important;
}

.btn-close{
	color: $text-color-secondary;
}

.btn-viewsite{
	background: #8f8d83;
	color: $text-color-primary;
	padding: 7px 15px;
	@include on-event{
		background-color: darken(#8f8d83, 12%);
		color: $text-color-primary;
	}
}

.btn-logout{
	background: #00a2d3;
	color: $text-color-primary;
	padding: 7px 20px;
	@include on-event{
		background-color: darken(#00a2d3, 12%);
		color: $text-color-primary;
	}
}

.btn-modify{
	background-color: $bg-forth;
	color: $text-color-primary;
	@include on-event{
		background-color: darken($bg-forth, 12%);
		color: $text-color-primary;
	}
}

.btn-view{
	background-color: $bg-secondary-shade6;
	color: $text-color-primary;
	@include on-event{
		background-color: darken($bg-secondary-shade6, 12%);
		color: $text-color-primary;
	}
}

.btn-view-edit{
	background-color: $bg-secondary-shade7;
	color: $text-color-primary;
	padding: 20px 30px;
	@include font-size(16px);
	font-weight: 600;
	@include breakpoint("desktop-medium"){
		padding: 10px 20px;
	}
	@include on-event{
		backgrond-color: darken($bg-secondary-shade7, 12%);
		color: $text-color-primary;
	}
}

.btn-cancel{
	background: $lighGray;
	@include on-event{
		background-color: darken($lighGray, 12%);
		color: $text-color-primary;
	}
}

.btn-save{
	background: $bg-brand;
	margin-left: 7px;
	@include on-event{
		background-color: darken($bg-brand, 12%);
		color: $text-color-primary;
	}
	&.js-please-wait {
		display: inline-block;
		width: 88px;
	    height: 62px;
	    padding: 0 5px;
	    text-align: center;
	    img {
	    	width: 60px
	    }
	}
}
.js-please-wait {
	img {
		width:74px;
	}
}

.btn-tertiary{
	background: $bg-secondary-shade2;
	color: $text-color-primary;
	@include on-event{
		background-color: darken($bg-secondary-shade2, 12%);
		color: $text-color-primary;
	}
}

.save-cont{
	.btn-save{
		float: left;
	}
	.btn-save-dropdown{
		background-color: $bg-brand;
		border-left: 1px solid $bg-primary;
		@include on-event{
			background-color: darken($bg-brand, 12%);
			color: $text-color-primary;
		}
	}
	.dropdown-menu{
		left: 5px;
		width: 100%;
		text-align: center;
		li{
			a{
				@include font-size(16px);
			}
		}
	}
}

.btn-hide{
	@include font-size(14px);
	background: transparent;
	border-color: #929292;
	color: #929292;
}
.btn-add {
	color: #fff;
	background: #00a2d3;
	@include on-event{
		background-color: darken(#00a2d3, 12%);
		color: $text-color-primary;
	}
}
.btn-add-category {
	@extend .btn-danger;
	padding: 10px 20px;
    border-radius: 5px;
}
.btn-close-category {
    padding: 10px 20px;
    border-radius: 5px;
}
.btn-back {
	background-color: #607D8B;
    color: #fff;
	@include on-event{
		background-color: darken(#607D8B, 12%);
		color: #fff;
	}
}