@charset 'UTF-8';

@import 'variables-override';
//Bootstrap 3.3.7
@import 
  'vendor/bootstrap',
  'vendor/flex',
  'vendor/flex-extension';

// 1. Configuration and helpers
@import
  'abstracts/variables',
  'abstracts/functions',
  'abstracts/mixins';

// 2. Vendors
@import
  'vendor/normalize',
  'vendor/animate';

// 3. Base stuff
@import
  'base/base',
  'base/fonts',
  'base/typography',
  'base/helpers';

// 4. Layout-related sections
@import
  'layout/header',
  'layout/footer',
  'layout/navigation',
  'layout/cart';

// 5. Components
@import
  'components/button',
  'components/animation',
  'components/preview',
  'components/colors',
  'components/datepicker';

// 6. Page-specific styles
@import
  'pages/plugin-configuration',
  'pages/inclusion',
  'pages/not-found',
  'pages/subscriber',
  'pages/login',
  'pages/home';

// 7. Themes
@import
  'themes/default';
