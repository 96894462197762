// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------
.form_partitions svg {
	max-width: 100%;
	height: auto;
}
.label-inline {
	white-space:nowrap;
	margin-right:10px;
}
.bg-light-gray {
	background-color:#ddd;
}
.text-light {
	font-weight:100;
}
.text-italic {
	font-style:italic
}
.text-white {
	color:$white;
}
.text-bold {
	font-weight:700;
}
.text-h4 {
	@include font-size(24px);
	span:last-child {
		color: #666666;
		font-weight: normal;
		margin-left: 15px;
		@include font-size(14px);
	}
}
.text-red {
	color:#e10505;
}
.modal {
	&-header {
	    padding: 25px;
	}
	&__actions {
		margin: 0;
    padding: 20px;
	    text-align: right;
	    background: #ddd;

		li {
			display: inline-block;
			list-style:none;

			&:first-child {
				margin-right: 10px;
			}
		}
	}
}
.btm-element {
	margin:30px -21px -21px;
	background-color:#ddd;
}
.preview-color {
	width: 20px;
    height: 20px;
    border-radius: 50%;
    margin:0 auto
}
.svg-preview{
	@include prefix((
		transition: transform ease-in-out 0.3s,
		transform-origin:top right
    ), webkit moz);
	&:hover  {
		background-color:#fff;
	    @include prefix((
			transform: scale(5),
			transition: transform ease-in-out 0.3s,
		    box-shadow: 0 0 3px rgba(0,0,0,0.4)
	    ), webkit moz);
	}
	svg {
		max-width: 150px;
	}
}
.list-colors {
	@include flexbox();
	@include flex-wrap(wrap);
	.preview-color {
		margin:0 2px;
	}
}

select.error, input.error {
    border-color: #a94442;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
}

label.error {
    color: #a94442;
    display: block;
    margin-bottom: 10px;
    margin-top: 5px;
}

label.checkbox input[type=checkbox] {
    margin: 2px -22px;
}

button.multiselect {
	@include flexbox();
	@include justify-content(space-between);
	.caret {
		margin-top: 8px;
	}
}

.product-builder tbody tr {
    cursor: pointer;
}
.toggle-custom .toggle-on, .toggle-custom .toggle-on.active,
.toggle-custom .toggle-off, .toggle-custom .toggle-off.active {
	line-height:1.5 !important
}
div#product_image_form {

	img {
		max-width: 500px;
		margin: 30px auto;
		display: block;
	}
}
.i4-tooltip {
	position:relative;
	&:hover {
		&::before, &::after {
			opacity: 1;
			visibility: visible;
		}
	}
	&::before, &::after {
		left: 50%;
		opacity: 0;
		visibility: hidden;
		@include prefix((
			transform: translateX(-50%),
			transition:all 0.3s ease-in-out
	    ), webkit moz);
	}
	&::after {
	    content: attr(data-txt);
	    position: absolute;
	    background-color: rgba(0,0,0,0.8);
	    color: #fff;
	    font-size: 12px;
	    padding: 1px 6px;
	    border-radius: 4px;
	    top: -22px;
	}
	&::before {
	    width: 0;
	    height: 0;
	    border-left: 5px solid transparent;
	    border-right: 5px solid transparent;
	    border-top: 5px solid rgba(0,0,0,0.8);
	    content: '';
	    position: absolute;
	    top: -3px;
	}
}
.has-icons-actions {
	input.form-control {
	    display: inline-block;
	    width: auto;
	    height: 30px;
	    padding: 0 10px;
	    margin: 0 5px;
	    &.js-base-price-input {
	    	max-width: 100px;
	    }
	}
}
a.icon-action {
    border-left: 1px solid #ffd7d7;
    padding: 0 10px;
    opacity: 0.5;
    &:hover {
    	opacity:1;
    }
}
span.fancytree-title,
.js-base-price {
    margin-right: 8px;
}
.forgot-password,
.reset-password {
	input {
		background: #f3f3f3;
		border: 0;
		outline: 0;
		height: 60px;
		margin: 30px auto 0;
		@include font-size(18px)
	}
	.btn {
	    width: 100%;
	}
	h1 {
		@include font-size(30px)
	}
}
.uploaded{
	&-img {
		margin-bottom:10px;
	}
	&-logo {
		max-width: 100px;
	}
}
.preview-wrapper {
    background-color: #f9f9f9;
    padding: 30px;
    margin-bottom: 50px;
    label {
		font-size: 18px;
		border-bottom: 1px solid #ddd;
		display: block;
    }
}
.full-width {
	width:100%;
}
.scrollable-table {
	overflow-x:auto;
	padding: 20px 0;
}
.note {
    color: #dc3545;
    display: inline-block;
    padding: 10px 0 0;
    font-size: 15px;
    font-size: 0.9375rem;
}
.full-width {
	width: 100% !important;
}