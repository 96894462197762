/**
 * Basic typography style for copy text
 */
body {
  font-family: $primaryFont;
  font-size: 100%
}

.semibold{
	font-weight: 600;
}

.bold{
	font-weight: 700;
}

.font16{
	@include font-size(18px);
}
.font18{
	@include font-size(18px);
}
.font24{
	@include font-size(24px);
}