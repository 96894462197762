/***
	JUSTIFY CONTENT =================================
	flex-(device-width)-h-jc-c = justify-content(center)
	flex-(device-width)-h-jc-fe = justify-content(flex-end)
	flex-(device-width)-h-jc-fs = justify-content(flex-start)
	flex-(device-width)-h-jc-sb = justify-content(space-between)
	flex-(device-width)-h-jc-sa = justify-content(space-around)
	flex-(device-width)-h-jc-sE = justify-content(space-evenly)

	ALIGN ITEMS =====================================
	flex-(device-width)-v-ai-b = align-items(baseline)
	flex-(device-width)-v-ai-c = align-items(center)
	flex-(device-width)-v-ai-s = align-items(stretch)
	flex-(device-width)-v-ai-fe = align-items(flex-end)
	flex-(device-width)-v-ai-fs = align-items(flex-start)

	ALIGN SELF ======================================
	flex-(device-width)-as-a = align-self(auto)
	flex-(device-width)-as-b = align-self(baseline)
	flex-(device-width)-as-s = align-self(stretch)
	flex-(device-width)-as-fe = align-self(flex-end)
	flex-(device-width)-as-fs = align-self(flex-start)

	ALIGN CONTENT ===================================
	flex-(device-width)-ac-c = align-content(center)
	flex-(device-width)-ac-s = align-content(stretch)
	flex-(device-width)-ac-fe = align-content(flex-end)
	flex-(device-width)-ac-fs = align-content(flex-start)
	flex-(device-width)-ac-sa = align-content(space-around)
	flex-(device-width)-ac-sb = align-content(space-between)

	WRAP ============================================
	flex-(device-width)-w-n = flex-wrap(nowrap)
	flex-(device-width)-w-w = flex-wrap(wrap)
	flex-(device-width)-w-wr = flex-wrap(wrap-reverse)
**/

.flex {
	@include flexbox();
}
.flex-xs-h-jc-c {
	@include justify-content(center);
}
.flex-xs-h-jc-fe {
	@include justify-content(flex-end);
}
.flex-xs-h-jc-fs {
	@include justify-content(flex-start);
}
.flex-xs-h-jc-sb {
	@include justify-content(space-between);
}
.flex-xs-h-jc-sa {
	@include justify-content(space-around);
}
.flex-xs-h-jc-se{
	@include justify-content(space-evenly);
}

.flex-xs-v-ai-c {
	@include align-items(center);
}
.flex-xs-v-ai-fe {
	@include align-items(flex-end);
}
.flex-xs-v-ai-fs {
	@include align-items(flex-start);
}
.flex-xs-v-ai-b {
	@include align-items(baseline);
}
.flex-xs-v-ai-s{
	@include align-items(stretch);
}

.flex-xs-w-n {
	@include flex-wrap(nowrap);
}
.flex-xs-w-w {
	@include flex-wrap(wrap);
}
.flex-xs-w-wr {
	@include flex-wrap(wrap-reverse);
}

.flex-xs-as-fs {
	@include align-self(flex-start);
}
.flex-xs-as-fe {
	@include align-self(flex-end);
}
.flex-xs-as-a {
	@include align-self(auto);
}
.flex-xs-as-b {
	@include align-self(baseline);
}
.flex-xs-as-c {
	@include align-self(center);
}
.flex-xs-as-s {
	@include align-self(stretch);
}

.flex-xs-ac-c {
	@include align-content(center);
}
.flex-xs-ac-s {
	@include align-content(stretch);
}
.flex-xs-ac-fs {
	@include align-content(flex-start);
}
.flex-xs-ac-fe {
	@include align-content(flex-end);
}
.flex-xs-ac-sb {
	@include align-content(space-between);
}
.flex-xs-ac-sa {
	@include align-content(space-around);
}
@media (min-width: 768px) {
	.flex-sm-h-jc-c {
		@include justify-content(center);
	}
	.flex-sm-h-jc-fe {
		@include justify-content(flex-end);
	}
	.flex-sm-h-jc-fs {
		@include justify-content(flex-start);
	}
	.flex-sm-h-jc-sb {
		@include justify-content(space-between);
	}
	.flex-sm-h-jc-sa {
		@include justify-content(space-around);
	}
	.flex-sm-h-jc-se{
		@include justify-content(space-evenly);
	}

	.flex-sm-v-ai-c {
		@include align-items(center);
	}
	.flex-sm-v-ai-fe {
		@include align-items(flex-end);
	}
	.flex-sm-v-ai-fs {
		@include align-items(flex-start);
	}
	.flex-sm-v-ai-b {
		@include align-items(baseline);
	}
	.flex-sm-v-ai-s{
		@include align-items(stretch);
	}

	.flex-sm-w-n {
		@include flex-wrap(nowrap);
	}
	.flex-sm-w-w {
		@include flex-wrap(wrap);
	}
	.flex-sm-w-wr {
		@include flex-wrap(wrap-reverse);
	}

	.flex-sm-as-fs {
		@include align-self(flex-start);
	}
	.flex-sm-as-fe {
		@include align-self(flex-end);
	}
	.flex-sm-as-a {
		@include align-self(auto);
	}
	.flex-sm-as-b {
		@include align-self(baseline);
	}
	.flex-sm-as-c {
		@include align-self(center);
	}
	.flex-sm-as-s {
		@include align-self(stretch);
	}

	.flex-sm-ac-c {
		@include align-content(center);
	}
	.flex-sm-ac-s {
		@include align-content(stretch);
	}
	.flex-sm-ac-fs {
		@include align-content(flex-start);
	}
	.flex-sm-ac-fe {
		@include align-content(flex-end);
	}
	.flex-sm-ac-sb {
		@include align-content(space-between);
	}
	.flex-sm-ac-sa {
		@include align-content(space-around);
	}
}
@media (min-width: 992px) {
	.flex-md-h-jc-c {
		@include justify-content(center);
	}
	.flex-md-h-jc-fe {
		@include justify-content(flex-end);
	}
	.flex-md-h-jc-fs {
		@include justify-content(flex-start);
	}
	.flex-md-h-jc-sb {
		@include justify-content(space-between);
	}
	.flex-md-h-jc-sa {
		@include justify-content(space-around);
	}
	.flex-md-h-jc-se{
		@include justify-content(space-evenly);
	}
	.flex-md-v-ai-c {
		@include align-items(center);
	}
	.flex-md-v-ai-fe {
		@include align-items(flex-end);
	}
	.flex-md-v-ai-fs {
		@include align-items(flex-start);
	}
	.flex-md-v-ai-b {
		@include align-items(baseline);
	}
	.flex-md-v-ai-s{
		@include align-items(stretch);
	}

	.flex-md-w-n {
		@include flex-wrap(nowrap);
	}
	.flex-md-w-w {
		@include flex-wrap(wrap);
	}
	.flex-md-w-wr {
		@include flex-wrap(wrap-reverse);
	}

	.flex-md-as-fs {
		@include align-self(flex-start);
	}
	.flex-md-as-fe {
		@include align-self(flex-end);
	}
	.flex-md-as-a {
		@include align-self(auto);
	}
	.flex-md-as-b {
		@include align-self(baseline);
	}
	.flex-md-as-c {
		@include align-self(center);
	}
	.flex-md-as-s {
		@include align-self(stretch);
	}

	.flex-md-ac-c {
		@include align-content(center);
	}
	.flex-md-ac-s {
		@include align-content(stretch);
	}
	.flex-md-ac-fs {
		@include align-content(flex-start);
	}
	.flex-md-ac-fe {
		@include align-content(flex-end);
	}
	.flex-md-ac-sb {
		@include align-content(space-between);
	}
	.flex-md-ac-sa {
		@include align-content(space-around);
	}
}
@media (min-width: 1200px) {
	.flex-lg-h-jc-c {
		@include justify-content(center);
	}
	.flex-lg-h-jc-fe {
		@include justify-content(flex-end);
	}
	.flex-lg-h-jc-fs {
		@include justify-content(flex-start);
	}
	.flex-lg-h-jc-sb {
		@include justify-content(space-between);
	}
	.flex-lg-h-jc-sa {
		@include justify-content(space-around);
	}
	.flex-lg-h-jc-se{
		@include justify-content(space-evenly);
	}

	.flex-lg-v-ai-c {
		@include align-items(center);
	}
	.flex-lg-v-ai-fe {
		@include align-items(flex-end);
	}
	.flex-lg-v-ai-fs {
		@include align-items(flex-start);
	}
	.flex-lg-v-ai-b {
		@include align-items(baseline);
	}
	.flex-lg-v-ai-s{
		@include align-items(stretch);
	}

	.flex-lg-w-n {
		@include flex-wrap(nowrap);
	}
	.flex-lg-w-w {
		@include flex-wrap(wrap);
	}
	.flex-lg-w-wr {
		@include flex-wrap(wrap-reverse);
	}

	.flex-lg-as-fs {
		@include align-self(flex-start);
	}
	.flex-lg-as-fe {
		@include align-self(flex-end);
	}
	.flex-lg-as-a {
		@include align-self(auto);
	}
	.flex-lg-as-b {
		@include align-self(baseline);
	}
	.flex-lg-as-c {
		@include align-self(center);
	}
	.flex-lg-as-s {
		@include align-self(stretch);
	}

	.flex-lg-ac-c {
		@include align-content(center);
	}
	.flex-lg-ac-s {
		@include align-content(stretch);
	}
	.flex-lg-ac-fs {
		@include align-content(flex-start);
	}
	.flex-lg-ac-fe {
		@include align-content(flex-end);
	}
	.flex-lg-ac-sb {
		@include align-content(space-between);
	}
	.flex-lg-ac-sa {
		@include align-content(space-around);
	}
}
