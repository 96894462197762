.subscriber {
	.group-elements {
		margin-bottom:10px;
		height: 40px;
		&:before, &:after {
			content:'';
			display: table;
		}
		&:after {
			clear: both;
		}
	}
	.company-logo {
		width:70px;
		height:70px;

		img {
			width:100%;
		}
	}
}
.plug-in-setup {
	table tr td {
		vertical-align: top;
	}
}