// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

footer {
	width: 100%;
	bottom: 0px;	
	padding: 25px 15px;
	border-top: 1px solid #ddd;
	margin-top: 40px;
}
