.subscription-form{
	background-color: #fbfbfb;
	margin: -31px -41px 40px;
	padding: 20px 40px 50px;
	&--field {
		margin-bottom:13px;
		@include flex(1 0 50%);
		label {
			margin-right: 10px;
			font-weight: 700;
			min-width: 150px;
		}
	}
	&__actions {
		margin-top: 30px;
	}
	.alert {
		margin-bottom: 0;
	}
	.alert, .btn3 {
	    margin-top: 35px;
	}
	.btn3 {
	 	padding: 15px 20px;
	}
	.js-please-wait img {
		margin-top: 20px;
	}
	.header {
		padding-left:0;
	}
	.toggle-custom {
		display: inline-block;
	    vertical-align: middle;
	    margin: 0;
	}

}