.preview {
    &-actions { 
        min-width:123px;
    }
    &-color {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin:0 auto
    }
}
.partitions {
    width: 100%;
}
.partition{
    &-label {
        margin: 5px 0 10px;
        cursor: move;
        @include prefix((
            display: flex,
            align-items: center
        ), webkit moz);
    }
    &-item {
        height: 0;
        overflow:hidden;
        @include prefix((
            display: flex,
            justify-content: space-between,
            align-items: center
        ), webkit moz);
    }
}
.ui-sortable .collapse {
    display: block !important;

    &.in  .partition-item {
        height: auto;
        padding: 6px;
        border-top: 1px solid #ddd;
    }
}
.svg-wrapper {
    position:relative;
    margin:0 auto;
    svg {
        width: 100%;
        height: 100%;
    }
    .svg_logo {
        z-index: 9;
    }
    .svg_container  {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }
    &__inner div {
        pointer-events: none
    }
}
.base-image {
    border:1px solid #ddd;
    max-width:100%;
    padding:10px;
    @extend .text-center;
    &-holder {
        position:relative;
        top:0;
        left:0;
        z-index: 2;
        width:100%;
        padding-bottom:42.2%;
        background-position:center;
        background-repeat:no-repeat;
        background-size: contain;

    }
}
span.grippy {
    width: 20px;
    height: 25px;
    display: inline-block;
    overflow: hidden;
    line-height: 5px;
    font-size: 12px;
    font-family: sans-serif;
    letter-spacing: 2px;
    color: #cccccc;
    text-shadow: 1px 0 1px black;
    &::after {
        content: '.. .. .. ..';
    }
}