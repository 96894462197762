.text-warning {
    color: $text-warning !important;
}
.bg{
	&-red {
	    background-color:#e10606;
	}
	&-branding {
		background-color: $branding;
	}
}
.accent {
    background-color:$white;
}