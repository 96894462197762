.login{
    background: #323232;
    width: 100%;
    min-height: 100vh;

    &-col {
        @include flex(1)
    }
    .branding {
        padding:0 2.604166666666667vw 0 30px;
        @extend .text-center;
        img{
            margin-bottom: 5px;
            width: 430px;
            max-width: 95%;
        }
    }
    &-left__inner,
    form  {
        padding-top:11.979166666666668vw;
    }
    &-left {
        width: 100%;
        @include linear-gradient(to bottom right, #00A69C 0%, $branding 100%);
        &__inner {
            width: 100%;
        }
    }
    .arrow {
        width: 12.5vw;
        position: relative;
        padding-top: 17.020833vw;
        svg {
            width: 6.25vw;
        }
        &::after {
            content: '';
            width: 50%;
            height: 100%;
            background-color: #323232;
            position: absolute;
            top: 0;
            right: 0;
        }
    }
    .btn-default{
        padding: 17px 27px;
        @include font-size(18px);
        font-weight: 600;
        margin-top: 5px;
        transition: background .5s ease;
        @include on-event{
            background-color: darken($branding, 12%);
        }
    }
    form {
        max-width: 475px; 
        padding-left: 2.0833333333333335vw
    }
    .form-group{
        background: #f3f3f3;
        border-radius: 5px;
        &:last-child{
            background: transparent;
            border-radius: 0;
        }
        span{
            text-transform: uppercase;
            @include font-size(18px);
            font-weight: 600;
            background: $bg-primary;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            padding: 17px;
            color: #333;
            img{
                margin: 0 10px 0 0;
            }
        }
        .form-control{
            background: #f3f3f3;
            border: 0;
            outline: 0;
            height: 60px;
            margin-top: 0;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            @include font-size(18px);
            @include on-event{
                outline: 0;
            }
        }
        p{
            color: $text-color-primary;
            @include font-size(18px);
        }
        a{
            color: $text-color-primary;
            text-decoration: underline;
        }
    }
}
.back-to-home {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    padding: 20px;
    .btn {
        background-color: #333;
        color: #fff;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 15px;
        &:hover {
            background-color:$branding;
        }
    }
}