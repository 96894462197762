.sidemenu{
    &::-webkit-scrollbar
    {
      width: 8px;  /* for vertical scrollbars */
      height: 12px; /* for horizontal scrollbars */
    }

    &::-webkit-scrollbar-track
    {
      background: rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb
    {
      background: rgba(255,255,255, 0.7);
    }
    &__logo {
        display: block;
        padding: 30px 10px;
        @include linear-gradient(to bottom right, #00A69C 0%, $branding 100%);
    }
}