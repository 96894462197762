.beat {
	@include animation(beat 3s ease-in-out infinite)
}

@include keyframes(beat){
	0%, 70% {
		@include prefix((
			box-shadow:0px 0px 0px 0px rgba(217, 83, 79, 0.5)
		), webkit moz);
	}
	100% {
		@include prefix((
			box-shadow:0px 0px 0px 30px rgba(217, 83, 79, 0)
		), webkit moz);
	}
}