// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
 *:focus {
    outline: none;
}
*, *::before, *::after {
  box-sizing: inherit;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  box-sizing: border-box;
  font-size: 100%;
  position: relative;
  min-height: 100%;
}

html, body, button, input, optgroup, select, textarea {
  font-family: $primaryFont;
}

img{
    max-width: 100%;
}

input[type="radio"]{
    -webkit-appearance: radio !important;
    outline: 0 !important;
}
.content__holder {
    margin-bottom: 30px 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    padding:20px;
    min-height: calc(100vh - 233px);
    @extend .accent;
    @include prefix((
        box-shadow: 0 1px 1px rgba(0,0,0,0.05)
    ), webkit moz);
}
/**
 * Basic styles for links
 */
a {
  color: $text-color-brand;
  text-decoration: none;

  @include on-event {
    color: $text-color-secondary;
    text-decoration: none;
  }
}
label{
      text-transform: uppercase;
      color: #444;
      font-weight: 400;
      @include font-size(14px);
      @include breakpoint("desktop-medium"){
        @include font-size(12px);
      }
  }
.label-status{
    background-color: $bg-primary-shade7;
    color: #666;
    width: 100%;
    max-width: 90px;
    padding: 5px 10px;
    display: inline-block;
    text-align: left;
    border-radius: 5px;
    font-weight: 600;
    &.label--cancelled{
        color: $text-color-danger;
    }
    &.label--delivered{
        color: #0099cc;
    }
}
figure{
    padding: 0;
    margin: 0;
}
.form-control{
    border: 1px solid #d9d9d9;
    &[disabled]{
        color: #999;
    }
    @include breakpoint("phone-large"){
        height: 45px;
        @include font-size(14px);
    }
}
input:focus, textarea:focus, select:focus {
    outline-color: transparent;
    outline-style: none;
    -webkit-appearance: none;

}
select {
    outline:none;
    text-indent: 1px;
    text-overflow: '';
    position: relative;
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: 95%;
    padding: 0 10px;
    border: 1px solid #ccc;
    @include prefix((
        appearance: none
    ), webkit moz);

    &.form-control{
        background: url("../images/caret.png") no-repeat center right;
        padding: 0 25px 0 15px;
    }

    &.select-action {
        background-color: #e9e9e9;
        padding: 8px;
        border-radius: 4px;
        @include font-size(12px);
        @include prefix((
            box-shadow: 0 2px 2px rgba(0,0,0,0.2),
            appearance: menulist
        ), webkit moz);
    }
}
.modal {
  text-align: center;
  padding: 0!important;
  &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -4px;
    }
    .modal-title{
        @include font-size(22px);
    }
    .modal-dialog {
      display: inline-block;
      text-align: left;
      vertical-align: middle;
    }
}
.tab-content{
    background-color: $bg-primary;
    border-radius: 7px;
    border-top-left-radius: 0;
    border: 1px solid #ddd;
    border-top: 0;
}

.title {
	font-family: $primaryFont;
}
.title--big {
	@include font-size(30px);
}
.title--red {
	color: $text-color-brand;
}

.panel--primary{
    border: 1px solid #e9e9e9;
    background-color: $bg-primary-shade1;
    .date-calendar{
        .col-xs-5{
            @include flexbox();
        }
        button{
            border: 0;
            background: transparent;
            outline: 0;
            padding-left: 7%;
        }
    }
}

.heading--icon{
    @include flexbox();
    @include align-items(center);
    @include breakpoint("desktop"){
        padding: 0 5px;
    }
    img{
        margin-right: 3%;
    }
}

.panel--toggle{
    @include flexbox();
    @include align-items(center);
    .img-title{
        margin-top: -10px;
    }
    .flexbox{
        flex: 1;
        align-items: center;
    }
    label{
        margin: 0 5%;
    }

}

div.panel{
    min-height: calc(100vh - 255px);
    @include prefix((
        box-shadow:none
    ), webkit moz); 
    div.panel-heading.heading--large{
        padding: 20px 15px;
        background-color: $bg-primary-shade1;
        border: 0;
        @include breakpoint("desktop"){
            padding: 15px;
        }
        &.border-bottom{
            background: $bg-primary;
            border-bottom: 1px solid #e9e9e9;
        }
        h1.panel-heading__title{
            @include font-size(24px);
            font-weight: 600;
            @include breakpoint("desktop"){
                @include font-size(20px);
            }
            &.panel-heading__title--green{
                color: #33cc66;
            }
            &.panel-heading__title--black{
                color: #666;
            }
        }
    }
    div.panel-heading.heading--small{
        padding: 20px 15px 10px;
        border: 0;
    }
    @include breakpoint("desktop-medium"){
        .panel-body{
            padding: 25px 15px;
        }
    }
}

.row-prod{
    margin-left: -25px;
    margin-right: -25px;
    .col-sm-3{
        padding-left: 25px;
        padding-right: 25px;
    }
}

.table{
    .treegrid-expander{
        margin-right: 10px;
    }
}

.list-table{
    padding: 0 0 30px !important;
    table{
        border-bottom: 1px solid #ddd;
        @include breakpoint("desktop"){
            @include font-size(14px);
        }
        input[type="checkbox"]{
            margin: 0 12px;
            @include on-event{
                -webkit-appearance: initial;
            }
        }
        tr{
            &.inactive{
                color: #d9534f;
                a{
                  color: #d9534f;
                }
            }
            th,td{
                vertical-align: top;

                @include breakpoint("desktop-medium"){
                    padding: 5px 10px;
                }
            }
            .btn{
                opacity: 0;
                @include breakpoint("desktop-medium"){
                    @include font-size(12px);
                    padding: 6px 10px;
                }
                i{
                    margin-right: 5px;
                }
            }
            &:hover{
                color: $text-color-secondary;
                .btn{
                    opacity: 1;
                }
                ul.actions {
                    opacity:1
                }

            }
            a {
                color: #333;
            }
        }
    }
}
ul.actions {
    padding:0;
    margin: 5px 0 0;
    opacity:0;
    &.visible {
        opacity:1;
    }

    li {
        list-style: none;
        display: inline-block;

        a {
            color: #00a2d3;
            @include font-size(14px);

            &:after {
                content: '|';
                margin: 0 5px;
                line-height: 1;
                position: relative;
                top: -1px;
                color: #c7c3c3;
            }

            &:hover {
                color: #0073aa;
            }

        }

        &:last-child a{
            &:after {
                display: none;
            }
        }
    }
    &--setup {
        li a {
          color: #00a2d3;  
        }
    }
}

.filter{
    background-color: $bg-secondary-shade5;
    padding: 15px 20px;
    form{
        @include flexbox();
        @include flex(1 0 auto);
        @include align-items(center);
        @include justify-content(space-between);
        &.form-export {
            @include flex(0);
        }
        .delete{
            @include flex(.5);
        }

        .form-group{
            @include flexbox();
            @include align-items(center);
            @include flex();
            margin-left: 20px;
            @media screen and (max-width: 1600px) and (min-width: 1500px){
                @include flex(1.2);
            }
            @include breakpoint("desktop-medium"){
                margin-left: 7px;
            }
            &:last-child{
                @include flex(.3);
            }
            &.status{
                @include flex(.7);
            }
            .form-control{
                width: 100%;
                @include breakpoint("desktop-medium"){
                    width: auto;
                    padding-right: 30px;
                }
            }
            &.date-calendar{
                .form-control{
                    max-width: 130px;
                    padding: 0 12px;
                    @include breakpoint("desktop"){
                        max-width: 90px;
                        padding: 0 7px;
                    }
                }
                button{
                    border: 0;
                    background: transparent;
                    outline: 0;
                    padding: 11px;
                }
            }
            select{
                background: $bg-primary url(/../images/icons/arrow-select.jpg) no-repeat;
                background-position: 90% 50%;
            }
        }
        .btn-group{
            .btn{
                padding: 10px 12px;
            }
        }
        label{
            margin-right: 15px;
            text-transform: capitalize;
            @include breakpoint("desktop-medium"){
                margin-right: 5px;
            }
        }
        button.btn-transparent{
            background: transparent;
            border: 0;
            outline: 0;
        }
    }
    &.filter--system{
        .flex3{
            @include flex(3);
        }
        .flex2{
            @include flex(2);
        }
        .flex1{
            @include flex();
        }
        .flex-pt9{
            @include flex(.9);
        }
        .flex-pt7{
            @include flex(.7);
        }
        .flex-pt5{
            @include flex(.5);
        }
        .flex-pt4{
            @include flex(.4);
        }
        .flex-pt3{
            @include flex(.3);
        }
    }
    @media screen and (max-width: 1600px) and (min-width: 1500px){
        &.filter--system .delete.flex-pt9{
            @include flex(.8);
        }
    }
}

.search{
    @include flexbox();
    @include align-items(flex-start);
    label{
        background: #ddd;
        padding: 10px 15px;
        border-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        text-transform: capitalize;
        @include font-size(14px);
    }
    .form-control{
        @include flex();
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
        @include on-event{
            outline: 0;
        }
    }
}


form .upload-queue {
    border: dashed 2px #c5c5c5;
    background: #ebebeb;
    min-height: 150px;
    .upload-queue-hint {
        font-style: normal;
        text-align: center;
        margin-top: 20px;
    }
}

.fileUpload {
    position: relative;
    overflow: hidden;
    margin: 10px;
}
.fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}
.input-file{
    border: 1px solid #ccc;
    border-radius: 5px;
    position:relative;
    padding:0 10px;

    .uploadFile{
        background: transparent;
        border: 0;
        width: 100%;
        padding: 11px 0;
        @include prefix((
            box-shadow:none
        ), webkit moz);
    }
    .fileUpload{
        position: absolute;
        border-color: #e4e4e4;
        top: 2px;
        right: 2px;
        margin: 0;
        background-color: #e4e4e4;
        @include font-size(16px);
    }
}
.header {
    margin-bottom: 30px;
    padding: 0 15px;
    @include font-size(25px);
}
.pagination{
    padding: 0 20px;
    text-align: right;
    a{
        color: $text-color-secondary;
        margin: 0 1px !important;
        border-radius: 2px;
        @include on-event{
            color: $text-color-primary;
        }
    }
}

input[type=checkbox] {
    border: 1px solid #b4b9be;
    background: #fff;
    color: #555;
    clear: none;
    cursor: pointer;
    display: inline-block;
    line-height: 0;
    height: 16px;
    margin: -4px 4px 0 0;
    outline: 0;
    padding: 0!important;
    text-align: center;
    vertical-align: middle;
    width: 16px;
    min-width: 16px;
    @include prefix((
        transition:.05s border-color ease-in-out,
        box-shadow: inset 0 1px 2px rgba(0,0,0,.1),
        appearance: none
    ), webkit moz);

    &:checked:before {
        font-family: 'FontAwesome';
        content: "\f00c";
        color: #197364;
        position: relative;
        top: 6px;
        font-size: 11px;
    }
}

.category {
    &-trigger {
        line-height: 0;
        a {
            @include font-size(30px);

            i {
                color:#333;
            }
        }
        @include transition(all 0.2s ease-in-out);
        @include transform(rotate(0deg));
        @include prefix((
            transform-origin:center
        ), webkit moz);

        &.collapsed {
            @include transform(rotate(-90deg));
            @include prefix((
                transform-origin:center
            ), webkit moz);

            a {
                i {
                    color:#737373;
                }
            }
        }
    }
    &-children {
        display: none;

        &.collapse.in {
            display: table-row-group;
        }

        tr td:nth-child(1) {
            padding-left:30px;
        }
    }
    &-parent:hover .category-trigger  a i{
        color: #000
    }
}

.product-partition {
    &s {
        display: none;

        .collapse.in {
            display: table-row-group;
        }
    }
    tr td:nth-child(1) {
        padding-left:30px;
    }
    &-trigger {
        line-height: 0;
        a {
            @include font-size(30px);

            i {
                color:#333;
            }
        }
        @include transition(all 0.2s ease-in-out);
        @include transform(rotate(0deg));
        @include prefix((
            transform-origin:center
        ), webkit moz);

        &.collapsed {
            @include transform(rotate(-90deg));
            @include prefix((
                transform-origin:center
            ), webkit moz);

            a {
                i {
                    color:#737373;
                }
            }
        }
    }
    &-parent:hover .product-partition-trigger  a i{
        color: #000
    }
}

.ui-autocomplete {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    display: none;
    min-width: 160px;
    _width: 160px;
    padding: 4px 0;
    margin: 2px 0 0 0;
    list-style: none;
    background-color: #ffffff;
    border:1px solid rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    *border-right-width: 2px;
    *border-bottom-width: 2px;
    @include prefix((
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2),
        border-radius: 5px
    ), webkit moz);

  .ui-menu-item > a.ui-corner-all {
    display: block;
    padding: 3px 15px;
    clear: both;
    font-weight: normal;
    line-height: 18px;
    color: #555555;
    white-space: nowrap;

    &.ui-state-hover, &.ui-state-active {
      color: #ffffff;
      text-decoration: none;
      background-color: #0088cc;
      border-radius: 0px;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      background-image: none;
    }
  }
}