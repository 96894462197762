// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

/// Rem font size with px fallback
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}
@mixin font-size($size) { 
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin line-height($sizes) { 
  line-height: $sizes;
  line-height: calculateRem($sizes);
}

@mixin transform($transforms) {
     -moz-transform: $transforms;
       -o-transform: $transforms;
      -ms-transform: $transforms;
  -webkit-transform: $transforms;
          transform: $transforms;
}

@mixin transition($transitions) {
     -moz-transition: $transitions;
       -o-transition: $transitions;
      -ms-transition: $transitions;
  -webkit-transition: $transitions;
          transition: $transitions;
}
@mixin box-shadow($box-shadow) {
     -moz-box-shadow: $box-shadow;
       -o-box-shadow: $box-shadow;
      -ms-box-shadow: $box-shadow;
  -webkit-box-shadow: $box-shadow;
          box-shadow: $box-shadow;
}

@mixin prefix($declarations, $prefixes: ()) {
  @each $property, $value in $declarations {
    @each $prefix in $prefixes {
      #{'-' + $prefix + '-' + $property}: $value;
    }

    // Output standard non-prefixed declaration
    #{$property}: $value;
  }
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}
 
// scale
@mixin scale($scale) {
   @include transform(scale($scale));
} 
// translate
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
   @include transform(skew(#{$x}deg, #{$y}deg));
}

/// Border radius
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}
/// Single side border-radius
@mixin border-top-radius($radius) {
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
   -webkit-border-top-left-radius: $radius;
   border-top-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
     -webkit-border-top-right-radius: $radius;
     border-top-right-radius: $radius;
     background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
   -webkit-border-bottom-left-radius: $radius;
   border-bottom-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
     -webkit-border-top-left-radius: $radius;
     border-top-left-radius: $radius;
     background-clip: padding-box;
}

@mixin placeholder($placeholder){
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $placeholder;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $placeholder;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: $placeholder;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: $placeholder;
  }
}

/// Breakpoint
@mixin breakpoint($break) {
  @if $break == hd {
    @media only screen and (min-width: 1601px) { @content; }
  } @else if $break == desktop {
    @media only screen and (max-width: 1600px) { @content; }
  } @else if $break == desktop-medium {
    @media only screen and (max-width: 1400px) { @content; }
  } @else if $break == tablet {
    @media only screen and (max-width: 1024px) { @content; }
  } @else if $break == tablet-port {
    @media only screen and (max-width: 768px) { @content; }
  } @else if $break == phone-large {
    @media only screen and (max-width: 767px) { @content; }
  } @else if $break == phone {
    @media only screen and (max-width: 540px) { @content; }
  } @else if $break == phone-small {
    @media only screen and (max-width: 400px) { @content; }
  } @else {
    @media only screen and ($break) { @content; }
  }
}


/// Gradient Colors
/// Convert angle
/// @author Chris Eppstein
/// @param {Number} $value - Value to convert
/// @param {String} $unit - Unit to convert to
/// @return {Number} Converted angle
@function convert-angle($value, $unit) {
  $convertable-units: deg grad turn rad;
  $conversion-factors: 1 (10grad/9deg) (1turn/360deg) (3.1415926rad/180deg);
  @if index($convertable-units, unit($value)) and index($convertable-units, $unit) {
    @return $value
             / nth($conversion-factors, index($convertable-units, unit($value)))
             * nth($conversion-factors, index($convertable-units, $unit));
  }

  @warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
}

/// Test if `$value` is an angle
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
  $is-direction: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

  @return $is-direction or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
@function legacy-direction($value) {
  @if is-direction($value) == false {
    @warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
  }

  $conversion-map: (
    to top          : bottom,
    to top right    : bottom left,
    to right top    : left bottom,
    to right        : left,
    to bottom right : top left,
    to right bottom : left top,
    to bottom       : top,
    to bottom left  : top right,
    to left bottom  : right top,
    to left         : right,
    to left top     : right bottom,
    to top left     : bottom right
  );

  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }

  @return 90deg - convert-angle($value, 'deg');
}

/// Mixin printing a linear-gradient
/// as well as a plain color fallback
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {String | List | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
  @if is-direction($direction) == false {
    $color-stops: ($direction, $color-stops);
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}


@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};

        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }
    -webkit-animation: $animations;
    -moz-animation:    $animations;
    -o-animation:      $animations;
    animation:         $animations;
}
@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}